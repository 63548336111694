import styles from '../css/app.pcss';
import {tns} from 'tiny-slider/src/tiny-slider';
var SimpleLightbox = require('simple-lightbox');


// App main
const main = async () => {
    // Import our CSS
    //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // Async load the vue module
    // const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
    // Create our vue instance
    // const vm = new Vue.default({
    //     el: "#app",
    //     components: {
    //         'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
    //     },
    //     data: {
    //     },
    //     methods: {
    //     },
    //     mounted() {
            
    //     },
    // });
};
// Execute async function
main().then( (value) => {
    document.getElementById('hamburger').onclick = function(){
        document.getElementById("navbar").classList.toggle("opened");
        document.getElementById("hamburger").classList.toggle("open");
    }

    var lightbox = new SimpleLightbox({elements: '.gallery .gallery-image'});
    
    var sliderArray = document.querySelectorAll('.banner-slider');
    sliderArray.forEach(function (el) {
        tns({
          container: el,
          mode: 'gallery',
            mouseDrag: true,
            controls: false,
            nav: true,
            navPosition: 'bottom',
            items: 1,
            autoplay: true,
            autoplayButtonOutput: false
        });
      });
    

});


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
